import * as Sentry from '@sentry/angular-ivy';
import {ErrorHandler, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AppInjector} from '../../core/app-injector.service';
import {User} from '../../core/models/user.model';
import {SnackbarComponent} from '../../shared/snackbar/snackbar.component';
import {SnackBarType} from '../../shared/snackbar/snack-bar-type.enum';

// When using strings, partial matches will be filtered out. If you need to filter by exact match, use regex patterns instead.
const ignoredErrors = [
	'ResizeObserver loop limit exceeded',
	'ResizeObserver loop completed with undelivered notifications',
	'Failed to fetch dynamically imported module',
	'Importing a module script failed',
	'Still DEACTIVATING, can not activate now',
	'Cannot read properties of undefined (reading \'setAttribute\')',
	'Failed to execute \'drawImage\' on \'CanvasRenderingContext2D\': The HTMLImageElement provided is in the \'broken\' state.',
	'NG04002: ',
	'OT_USER_MEDIA_ACCESS_DENIED'
];

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

	constructor(private translateService: TranslateService) {
		Sentry.init({
			dsn: environment.apiKeySentry,
			release: environment.appVersion,
			ignoreErrors: ignoredErrors,
			integrations: [
				// Registers the Replay integration,
				// which automatically captures Session Replays
				new Sentry.Replay(),
			],
			// Capture Replay for 0% of all sessions,
			// plus for 100% of sessions with an error
			replaysSessionSampleRate: 0,
			replaysOnErrorSampleRate: 1.0,
		});
	}

	handleError(error: any): void {
		if (environment.isDevelopment) {
			console.error(error);
		}
		const chunkFailedMessage = /Loading chunk [\d]+ failed/;
		if (chunkFailedMessage.test(error.message)) {
			this.handleLoadingChunkFailedError();
		} else {
			Sentry.captureException(error.originalError || error);
		}
	}

	handleLoadingChunkFailedError(): void {
		const snackBar: MatSnackBar = AppInjector.getInjector().get(MatSnackBar);
		snackBar.openFromComponent(SnackbarComponent, {
			data: {
				snackBarType: SnackBarType.error,
				text: this.translateService.instant('errors.newApplicationVersion')
			},
			panelClass: ['aga-custom-alert']
		});
		setTimeout(() => {
			window.location.reload();
		}, 10000);
	}

	static setSentryAdditionalInfo(user: User): void {
		Sentry.configureScope((scope) => {
			scope.setUser({
				username: user.fullName,
				email: user.email,
				id: user.uuid
			});
			scope.setTag('page_locale', localStorage.getItem('locale'));
			if (navigator) {
				scope.setContext('Network information', {
					isOnline: navigator.onLine,
					networkType: navigator['connection'] && navigator['connection']['effectiveType'],
					connectionDeviceType: navigator['connection'] && navigator['connection'].type,
					'downloadSpeed (Mbps)': navigator['connection'] && navigator['connection']['downlink'],
					isUserSavingData: navigator['connection'] && navigator['connection']['saveData'],
					'rtt (ms)': navigator['connection'] && navigator['connection']['rtt']
				});
			} else {
				scope.setContext('Navigator is not supported', {});
			}
		});
	}
}
