import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../models/user.model';
import {isApiTokenExpired} from '../../../utils/token.util';
import {AUTHENTICATION_STORAGE_KEYS} from '../authentication/authentication-keys';
import {environment} from '../../../environments/environment';
import {UserRole} from '../models/user-role.enum';
import {SentryErrorHandler} from '../../configurations/sentry/sentry-error-handler';

@Injectable()
export class UserAccountManagementService {
	private currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);
	public currentUserObservable: Observable<User> = this.currentUserSubject.asObservable();

	constructor() {
		this.setUserIfInStorage();
	}

	private setUserIfInStorage(): void {
		if (this.isUserInStorage()) {
			const parsedUser = JSON.parse(localStorage.getItem(AUTHENTICATION_STORAGE_KEYS.storedUser));
			const user: User = User.fromStorage(parsedUser);
			SentryErrorHandler.setSentryAdditionalInfo(user);
			this.currentUserSubject.next(user);
		}
	}

	isUserLoggedIn(): boolean {
		return this.currentUser !== null;
	}

	isUserInStorage(): boolean {
		return JSON.parse(localStorage.getItem(AUTHENTICATION_STORAGE_KEYS.storedUser)) !== null;
	}

	isUserAuthenticated(): boolean {
		return this.isUserLoggedIn() && !isApiTokenExpired(localStorage.getItem(AUTHENTICATION_STORAGE_KEYS.apiToken));
	}

	isUserAuthorized(authorizedUserRoles: Array<UserRole>): boolean {
		return environment.bypassEmployeeTypeAuthorization ||
			this.currentUser?.isAdmin() ||
			this.currentUser?.roles?.some(role => authorizedUserRoles.includes(role));
	}

	public get currentUser(): User {
		return this.currentUserSubject.value;
	}

	public set currentUser(user: User) {
		localStorage.setItem(AUTHENTICATION_STORAGE_KEYS.storedUser, JSON.stringify(user));
		SentryErrorHandler.setSentryAdditionalInfo(user);
		this.currentUserSubject.next(user);
	}

	clearUserData(): void {
		localStorage.removeItem(AUTHENTICATION_STORAGE_KEYS.apiToken);
		localStorage.removeItem(AUTHENTICATION_STORAGE_KEYS.storedUser);
		this.currentUserSubject.next(null);
	}
}
